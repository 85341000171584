import React, { useRef, useEffect } from "react";
import { motion, useInView } from "framer-motion";
const VideoPlayer = ({
  videoSrc,
  videoSrcMp4,
  poster,
  className = "",
  type = "video/mp4",
  isPosterOnly = false,
}) => {
  const videoRef = useRef(null);
  const inView = useInView(videoRef, {
    triggerOnce: true,
    threshold: 0.1,
  });
  useEffect(() => {
    if (videoRef?.current) {
      videoRef?.current
        .play()
        .catch(() => false);
    }
  }, []);

  return isPosterOnly ? (
    <img src={poster} alt="video poster" className={className} />
  ) : (
    <video
      ref={videoRef}
      poster={poster}
      muted
      loop
      playsInline
      autoPlay
      preload="none"
      width="100%"
      height="100%"
      role="img"
      className={className}
    >
      <source src={videoSrc} type={type} />
      {videoSrcMp4 && (
        <source src={inView ? videoSrcMp4 : null} type="video/mp4" />
      )}
    </video>
  );
};

export default VideoPlayer;
